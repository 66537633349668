<template>
  <div>
    <!-- Filters -->
    <project-status-list-filters
      :project-status-filter.sync="expenseNatureFilter"
      :project-status-options="projectStatusOptions"
    />

    <!-- Table Container Card -->
    <b-card no-body class="mb-0">
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Affichage</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>Statuts de Projets</label>
          </b-col>

          <!-- Search -->
          <b-col cols="12" md="6">
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                placeholder="Rechercher..."
              />
              <b-button
                variant="primary"
                @click="$router.push({ name: 'apps-project-status-add' })"
              >
                <span class="text-nowrap">Ajouter Statut</span>
              </b-button>
            </div>
          </b-col>
        </b-row>
      </div>

      <b-table
        ref="refProjectStatusListTable"
        class="position-relative"
        :items="fetchProjectStatusList"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="Pas de statut de projet trouvé"
        :sort-desc.sync="isSortDirDesc"
      >
        <!-- Column: Status -->
        <template #cell(expense_nature)="data">
          <b-badge :variant="data.item.expense_nature == 'projet' ? 'primary' : 'warning'" class="text-capitalize">
            {{ data.item.expense_nature }}
          </b-badge>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <div class="text-nowrap">
            <feather-icon
              :id="`project-status-row-${data.item.id}-edit-icon`"
              icon="EditIcon"
              class="cursor-pointer text-warning"
              size="16"
              @click="
                $router.push({
                  name: 'apps-project-status-edit',
                  params: { id: data.item.id },
                })
              "
            />
            <b-tooltip
              title="Modifier Statut"
              class="cursor-pointer"
              :target="`project-status-row-${data.item.id}-edit-icon`"
            />

            <feather-icon
              :id="`project-status-row-${data.item.id}-delete-icon`"
              icon="TrashIcon"
              size="16"
              class="mx-1 text-danger"
              v-if="data.item.created_by != null"
              @click="deleteProjectStatus(data.item.id)"
            />
            <b-tooltip
              v-if="data.item.created_by != null"
              title="Supprimer Statut"
              :target="`project-status-row-${data.item.id}-delete-icon`"
            />
          </div>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted"
              >Affichage de {{ dataMeta.from }} à {{ dataMeta.to }} sur
              {{ dataMeta.of }} statuts de projets</span
            >
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalProjectStatus"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
/* eslint-disable */
import {
  BTooltip,
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BFormCheckbox,
} from "bootstrap-vue";
import vSelect from "vue-select";
import ProjectStatusListFilters from "./ProjectStatusListFilters.vue";
import useProjectStatusList from "./useProjectStatusList";

export default {
  name: "ProjectStatusList",
  components: {
    ProjectStatusListFilters,

    BTooltip,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BFormCheckbox,

    vSelect,
  },
  setup() {
    const {
      fetchProjectStatusList,
      tableColumns,
      perPage,
      currentPage,
      totalProjectStatus,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refProjectStatusListTable,
      refetchData,
      toggleProjectStatusStatus,
      deleteProjectStatus,

      // Extra Filters
      expenseNatureFilter,
      projectStatusOptions,
    } = useProjectStatusList();

    return {
      fetchProjectStatusList,
      tableColumns,
      perPage,
      currentPage,
      totalProjectStatus,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refProjectStatusListTable,
      refetchData,
      toggleProjectStatusStatus,
      deleteProjectStatus,

      expenseNatureFilter,
      projectStatusOptions,
    };
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
